import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    isLoggedIn: null,
    email: "",
    company: "",
    company_address: "123 abc xyz",
    cust_id: "",
    cust_num: null,
    cust_name: "",
    vendor_id: "",
    vendor_num: "",
    vendor_name: "",
    cell_phone: "",
    phone: "",
    name: "",
    uid: 0,
    permision: {
      case: true,
      call: true,
      job: true,
      equipment: true,
      invoice: true,
      contract: true,
      order: true,
      shipment: true,
      quote: true,
      rma: true,
    },
    isUseCase: true,
  }),
  getters: getters,
  mutations: mutations,
  actions: actions,
};
