export default {
  async changeJobSelection(context, payload) {
    context.commit("changeJobSelection", payload);
  },
  async getJobListFromBAQ(context, payload) {
    context.commit("getJobListFromBAQ", payload);
  },
  async callMethodAttachment(context) {
    context.commit("callMethodAttachment");
  },
};
