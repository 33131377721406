export default {
  async logout(context) {
    context.commit("logout");
  },
  async updateInfo(context, payload) {
    context.commit("updateInfo", payload);
  },
  async DSPGetCompanyInfo(context) {
    context.commit("DSPGetCompanyInfo");
  },
  async getInfo(context) {
    context.commit("getInfo");
  },
  async loadUserInfo(context) {
    context.commit("loadUserInfo");
  },
};
