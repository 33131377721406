<template>
  <v-app style="background-color: #eef2f5">
    <v-main style="background-color: #eef2f5; width: 100vw">
      <aside
        :class="is_expanded ? 'is-expanded' : ''"
        style="position: fixed; z-index: 9999999"
      >
        <div class="logo">
          <img
            src="https://driftt.imgix.net/https%3A%2F%2Fdriftt.imgix.net%2Fhttps%253A%252F%252Fs3.amazonaws.com%252Fcustomer-api-avatars-prod%252F2106025%252Fea5a76cb46aecb00a3feb32cfaf95a6ai4rfc4ddkzx2%3Ffit%3Dmax%26fm%3Dpng%26h%3D200%26w%3D200%26s%3D2d641e0700b1b8ba2f8778de4f0fe8e3?fit=max&fm=png&h=200&w=200&s=dc865d3703d56f5236fb41594ff85894"
            alt="Vue"
          />
        </div>
        <div class="menu">
          <router-link
            to="/home"
            class="button d-flex justify-around align-center"
          >
            <span @click="changeExpand(0)"
              ><v-icon class="d-flex material-icons">{{
                "mdi-home"
              }}</v-icon></span
            >
            <span
              @click="changeExpand(0)"
              class="text width-100 d-flex justify-center align-center"
              >Home</span
            >
          </router-link>
          <div
            class="button d-flex justify-around button_menu"
            @click="ToggleMenu"
          >
            <span
              ><v-icon class="d-flex material-icons">{{
                "mdi-menu"
              }}</v-icon></span
            >
            <span
              class="text width-100 d-flex justify-center button_menu align-center"
              >Menu</span
            >
          </div>
          <div class="menu_list" @click="changeExpand(0)" v-show="isSupplier">
            <router-link
              @click="changeExpand(0)"
              to="/job-selection-supplier"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-select-search"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Jobs Selection</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/my-subcontract-job"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-file-document-edit"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >My Subcontract Jobs</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/job-calendar"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-calendar-clock"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Job Calendar</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/meter-reading"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-forklift"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Meter Reading</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/managed-equipment"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-forklift"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Managed Equipments</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/case"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-ticket"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Cases</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/maintenance-request"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-tray-full"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Maintenance Requests</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/managed-inventory"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-warehouse"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Managed Inventory</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/timesheet"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-bulletin-board"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Timesheet</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/purchase-order"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-receipt-text-outline"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Purchase Order</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/delivery"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-truck-delivery"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Delivery</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/billing"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-receipt-clock-outline"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Billing</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/AgedCare-Form"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-hospital-box"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Aged Care Form</span
              >
            </router-link>
          </div>
          <div class="menu_list" @click="changeExpand(0)" v-show="!isSupplier">
            <router-link
              @click="changeExpand(0)"
              to="/job-selection"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-select-search"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Jobs Selection</span
              >
            </router-link>
            <router-link
              to="/scheduling-board"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-bulletin-board"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Scheduling Board</span
              >
            </router-link>
            <router-link
              to="/operation-timeline"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-timeline-alert"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Operations Timeline</span
              >
            </router-link>
            <router-link
              to="/equip-management"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-forklift"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Equipment Management</span
              >
            </router-link>
            <router-link
              to="/maintenance-job"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-wrench-clock"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Maintenance Job</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/meter-reading"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-forklift"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Meter Reading</span
              >
            </router-link>
            <!-- <router-link
              to="/preventative-job"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-shield-alert"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Preventative Job</span
              >
            </router-link> -->
            <router-link
              to="/maintenance-request-queue"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-tray-full"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Maintenance Request Queue</span
              >
            </router-link>
            <router-link
              to="/equip-location"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-folder-marker"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Facility - Location</span
              >
            </router-link>
            <router-link
              to="/case-management"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)"
                ><v-icon class="d-flex material-icons">{{
                  "mdi-ticket"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Case Management</span
              >
            </router-link>
            <router-link
              @click="changeExpand(0)"
              to="/AgedCare-Form"
              class="button d-flex justify-around"
            >
              <span @click="changeExpand(0)">
                <v-icon class="d-flex material-icons">{{
                  "mdi-hospital-box"
                }}</v-icon></span
              >
              <span
                @click="changeExpand(0)"
                class="text width-100 d-flex justify-start align-center"
                >Aged Care Form</span
              >
            </router-link>
          </div>
        </div>
        <div class="flex"></div>
        <div class="menu">
          <router-link
            to="/portal-help"
            class="button d-flex justify-around align-center"
            :custom="true"
            v-slot="{ href }"
          >
            <a :href="href" @click="changeExpand(0)">
              <span
                ><v-icon class="d-flex material-icons">{{
                  "mdi-help-circle-outline"
                }}</v-icon></span
              >
              <span class="text width-100 d-flex justify-center align-center"
                >Help</span
              >
            </a>
          </router-link>
          <router-link
            to="/"
            class="button d-flex justify-around align-center"
            :custom="true"
            v-slot="{ href }"
          >
            <a :href="href" @click="changeExpand(2)">
              <span
                ><v-icon class="d-flex material-icons">{{
                  "mdi-logout"
                }}</v-icon></span
              >
              <span class="text width-100 d-flex justify-center align-center"
                >Log Out</span
              >
            </a>
          </router-link>
        </div>
      </aside>
      <router-view
        :class="is_expanded ? 'is_expanded_route_content' : 'route_content'"
        style="background-color: #eef2f5"
      ></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      is_expanded: false,
      isSupplier: false,
    };
  },
  mounted() {
    this.isSupplier =
      localStorage.getItem("isSupplierRole")?.toLowerCase?.() == "true";
  },
  computed: {
    ...mapGetters("User", ["getUserInfo", "getPermission"]),
  },
  methods: {
    changeExpand(index = 0) {
      index == 0
        ? (this.is_expanded = false)
        : this.$store.dispatch("User/logout");
    },
    ToggleMenu() {
      this.is_expanded = !this.is_expanded;
    },
  },
};
</script>
<style lang="scss" scoped>
.align-center {
  display: flex;
  justify-items: center;
  align-items: center;
}
.color-black {
  color: black;
}
.width-100 {
  width: 100%;
  height: 100%;
}
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");
.menu_list {
  display: none;
}
.v-icon {
  color: black;
}
aside {
  display: flex;
  flex-direction: column;
  background-color: #006780;
  color: black;
  width: calc(2rem + 25px);
  overflow: hidden;
  min-height: 100vh;
  padding: 1rem;
  transition: 0.2s ease-in-out;
  .button_menu {
    cursor: pointer;
  }
  span {
    text-decoration: none;
  }
  router-link {
    text-decoration: none;
  }

  .logo {
    margin-bottom: 1rem;
    margin-right: -4px;
    img {
      width: 25px;
    }
  }
  h3,
  .button .text {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    margin-left: 5px;
  }
  .text {
    opacity: 0;
    display: none;
    transition: opacity 0.3s ease-in-out;
    margin-left: 5px;
  }

  h3 {
    color: gray;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .menu {
    margin: 0 -1rem;

    .button {
      display: flex;
      height: 50px;
      align-items: center;
      text-decoration: none;

      transition: 0.2s ease-in-out;
      padding: 0.5rem 1rem;

      .material-icons {
        align-items: center;
        color: white;
        transition: 0.2s ease-in-out;
      }

      .text {
        color: white;
        transition: 0.2s ease-in-out;
      }

      &:hover {
        background-color: white;
        .v-icon {
          color: white;
        }
        .material-icons,
        .text {
          color: black;
          text-decoration: none;
        }
      }
    }
  }
  &.is-expanded {
    width: 25vw;
    max-width: 20vw;
    h3,
    .button .text {
      opacity: 1;
    }

    .menu_list {
      display: flex;
      flex-direction: column;
      overflow: auto;
      max-height: 60vh;
      background-color: #006780;
      scrollbar-width: thin;
    }

    .button {
      height: 50px;
      .material-icons {
        margin-right: 1rem;
      }
    }

    .text {
      opacity: 1;
      color: #000;
      text-decoration: none;
    }

    @media (max-width: 1000px) {
      width: 30vw;
      max-width: 25vw;
    }
    @media (max-width: 800px) {
      width: 100vw;
      max-width: 100vw;
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    z-index: 99;
  }
}
.child-flex > *,
.flex {
  flex: 1 1 auto;
  max-width: 100%;
}
.is_expanded_route_content {
  width: calc(80vw);
  margin-left: calc(20vw) !important;
}
.route_content {
  padding-left: calc(2rem + 25px);
}
</style>
