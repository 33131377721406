export default {
  getEquipments: (state) => state.equipments,
  getResources: (state) => state.resources,
  getResourceGroups: (state) => state.resourceGroups,
  getProjects: (state) => state.projects,
  getJobs: (state) => state.jobs,
  getSelection: (state) => state.selection,
  getSelectionType: (state) => state.selectionType,
  getJobList: (state) => state.jobList,
  getJobSelecteds: (state) => state.jobSelecteds,
};
