import { callMethodFunc, callBAQ } from "../Method/index";
import { saveAs } from "file-saver";

export default {
  async changeJobSelection(state, payload) {
    state.jobSelecteds = payload.list;
    state.selection = payload.selection;
    state.selectionType = payload.type;
  },
  async getJobListFromBAQ(state, payload) {
    let isSupplier =
      localStorage.getItem("isSupplierRole")?.toLowerCase?.() == "true";
    await callBAQ("DSPGetMTNJobs", {
      VendorNum: !isSupplier ? "0" : payload.Vendor + "",
    }).then((res) => {
      if (res && res.message == "error") {
        alert(res.error);
      } else {
        if (res && res.length > 0) {
          let resourceDataSource = [];
          let equipList = [];
          let projectList = [];
          let resourceGroup = [];
          let array = [];
          res.forEach((el) => {
            array.push({
              Id: el.JobHead_JobNum,
              Subject: el.Calculated_IsPreventJob
                ? "*" + el.JobHead_JobNum
                : el.JobHead_JobNum,
              StartTime: new Date(
                new Date(el.JobHead_StartDate).setHours(
                  el.JobHead_StartHour ? el.JobHead_StartHour : 0
                )
              ),
              EndTime: new Date(
                new Date(el.JobHead_DueDate).setHours(
                  el.JobHead_DueHour == el.JobHead_StartHour &&
                    el.JobHead_StartDate == el.JobHead_DueDate
                    ? el.JobHead_DueHour + 9
                    : el.JobHead_DueHour + 1
                )
              ),
              IsAllDay: false,
              equipID: el.JobHead_EquipID,
              projectID: el.JobHead_ProjectID,
              priority: el.JobHead_SchedCode,
              resourceID: el.Resource_ResourceID,
              imgID: el.Equip_ImageID_c,
              IsPreventJob: el.Calculated_IsPreventJob,
            });
            let a = 0;
            resourceDataSource.forEach((el1) => {
              if (el.Resource_ResourceID == el1.resourceID) {
                a = a + 1;
              }
            });
            if (a == 0 && el.Resource_ResourceID != "") {
              resourceDataSource.push({
                resourceID: el.Resource_ResourceID,
                resourceName: el.Resource_Description,
              });
            }
            a = 0;
            equipList.forEach((el1) => {
              if (el.JobHead_EquipID == el1.equipID) {
                a = a + 1;
              }
            });
            if (a == 0 && el.JobHead_EquipID != "") {
              equipList.push({
                equipID: el.JobHead_EquipID,
                equipName: el.JobHead_EquipID,
                imgID: el.Equip_ImageID_c,
                base64: "",
              });
            }
            a = 0;
            projectList.forEach((el1) => {
              if (el.JobHead_ProjectID == el1.projectID) {
                a = a + 1;
              }
            });
            if (a == 0 && el.JobHead_ProjectID != "") {
              projectList.push({
                projectID: el.JobHead_ProjectID,
                projectName: el.JobHead_ProjectID,
              });
            }
            a = 0;
            resourceGroup.forEach((el1) => {
              if (el.JobOpDtl_ResourceGrpID == el1.groupID) {
                a = a + 1;
              }
            });
            if (a == 0 && el.JobOpDtl_ResourceGrpID != "") {
              resourceGroup.push({
                groupID: el.JobOpDtl_ResourceGrpID,
                groupName: el.JobOpDtl_ResourceGrpID,
              });
            }
          });
          state.jobs = array;
          state.projects = projectList;
          state.equipments = equipList;
          state.resourceGroups = resourceGroup;
          state.jobList = res;
          state.resources = resourceDataSource;
        }
      }
    });
  },
  async callMethodAttachment(state) {
    state.projects = [];
    let bo = "Erp.BO.JobEntry";
    let method1 = "GetByIdandJobType";
    let body1 = {
      jobNum: "SRV0000030001",
      jobType: "SRV",
    };
    await callMethodFunc(bo, method1, body1).then(async (res1) => {
      if (res1 && res1.message == "error") {
        alert("res1" + res1.error);
      } else {
        let returnObj = JSON.parse(JSON.parse(res1.data).data).returnObj;
        let jobHeadAttachment = returnObj.JobHeadAttch;
        let firstAttach = jobHeadAttachment[0].XFileRefNum;

        let bo2 = "Ice.BO.Attachment";
        let method2 = "DownloadFile";
        let body2 = {
          xFileRefNum: firstAttach,
        };

        await callMethodFunc(bo2, method2, body2).then((res2) => {
          if (res2 && res2.message == "error") {
            alert("res2" + res2.error);
          } else {
            let returnObj2 = JSON.parse(JSON.parse(res2.data).data).returnObj;
            const byteCharacters = atob(returnObj2);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
              type: "application/octet-stream",
            });
            saveAs(blob, "ten_file");
          }
        });
      }
    });
  },
  async callMethodChangeDate(state, payload) {
    let c = state;
    state = c;
    c = payload;
    let bo = "Erp.BO.ScheduleEngine";
    let method1 = "MoveJobItem";
    let body1 = {
      ds: {
        ScheduleEngine: [
          {
            Company: "EPIC06",
            JobNum: "MTN2442",
            AssemblySeq: 0,
            OprSeq: 0,
            OpDtlSeq: 0,
            StartDate: "2023-10-10T00:00:00+07:00",
            EndDate: "2023-10-8T00:00:00+07:00",
            StartTime: 0,
            EndTime: 0,
            OverRideHistDateSetting: 2,
            WhatIf: false,
            SetupComplete: false,
            Finite: false,
            ProductionComplete: false,
            OverrideMtlCon: false,
            RecalcExpProdYld: false,
            UseSchedulingMultiJob: false,
            SchedulingMultiJobIgnoreLocks: false,
            SchedulingMultiJobMinimizeWIP: false,
            SchedTypeCode: "JJ",
            ScheduleDirection: "End",
            RowMod: "A",
          },
        ],
      },
    };
    await callMethodFunc(bo, method1, body1).then(async (res1) => {
      if (res1 && res1.message == "error") {
        alert(res1.error);
      } else {
        let returnObj = JSON.parse(JSON.parse(res1.data).data);
        let method2 = "Update";
        let body2 = {
          ds: {
            ...returnObj,
            JobOper: [
              {
                ...returnObj.JobOper.filter((i) => {
                  return (
                    i.JobNum == payload.curJob.JobHead_JobNum &&
                    i.OprSeq == payload.curOper.JobOper_OprSeq
                  );
                })[0],
                RowMod: "U",
                Sub_Complete_c: payload.complete,
              },
            ],
          },
        };
        await callMethodFunc(bo, method2, body2);
      }
    });
  },
};
