import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    jobSelecteds: [],
    resourceGroups: [],
    selection: [],
    selectionType: "",
    resources: [],
    projects: [],
    equipments: [],
    jobs: [],
    jobList: [],
  }),
  getters: getters,
  mutations: mutations,
  actions: actions,
};
